import React, { useEffect, useState } from 'react'
import PrevBannerSvg from '../../assets/assets_svg/prevbanner.svg'
import { Link, useLocation, useNavigate, useSearchParams  } from 'react-router-dom'
import { Form } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";

import { getApi, postApi } from "../../Helpers/Axios";

const Addform = () => {
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    let [searchParams, setSearchParams] = useSearchParams();
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        if(searchParams.get('edit_id')) {
            handleEdit()
        }
    }, [searchParams.get('edit_id')])

    const handleEdit = async() => {
        let url = `get-whatsapp-text?edit_id=${searchParams.get('edit_id')}`;
        await getApi(url, true).then((response) => {
            if(response.status === 200) {
                setInitialValueData(response?.data)
                setPreview(`${process.env.REACT_APP_UPLOAD_URL}whatsapp/${response?.data?.imageandvideo}`)
            }
        })
    }

    const [initialValues, setInitialValueData] = useState ({
        title: null,
        share_txt: null,
        imageandvideo: null,
        status: 0,
    });
    
    const validationSchema = Yup.object({
        title: Yup.string()
            .required("Share title is required."),
        share_txt: Yup.string()
            .required("Share text is required."),
        imageandvideo: Yup.mixed()
            .nullable()
            .test("fileFormat", "Unsupported Format", function(value) {
                if(!value && (["image/jpg", "image/jpeg", "image/png"].includes(value?.type))) {
                    return false;
                }
                return true;
            })
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            values.status = values.status ? 1 : 0;
            let url = searchParams.get('edit_id') ? `add-whatsapp-text?edit_id=${searchParams.get('edit_id')}` : 'add-whatsapp-text';
            await postApi(url, values, true, true)
                .then((response) => {
                    if(response?.status === 200) {
                        navigate("/whatsapp-share-text");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            setFieldValue("imageandvideo", file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setPreview(null);
    }

    return (
        <div>
            <div>
                <div>
                    <p className='breadcrumbs'><Link to='/dashboard'><span>Dashboard</span></Link> / <Link to='/whatsapp-share-text'><span>WhatsApp Share Text</span></Link> / <span>Add</span></p>
                </div>
            </div>
            <div className='banner-preview-box'>
                {!preview ? 
                    <img src={PrevBannerSvg} alt='Add Banner Icon' className='add-icon'></img> :
                    <img src={preview} alt='Add Banner Icon' className='banner-img-preview'></img>
                }
                <input type='file' id='upload-preview-images' onChange={handleImageChange} onBlur={handleBlur} className='d-none' />
            </div>
            {(errors?.imageandvideo && touched?.imageandvideo) ? <span className='require-text'>{errors?.imageandvideo}</span> : null}
            <label className='upload-preview-link theme-btn' htmlFor='upload-preview-images'>upload Image and Video</label>
            <div>
                <form method='post' className='add-detail-main' onSubmit={handleSubmit} encType='multipart/form-data'>
                    <div class="form-with-lable">
                        <label class="mt-1 form_label">Share Title</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter Share title' rows={14} name='title' onBlur={handleBlur} onChange={handleChange} value={values?.title} /><br />                        
                        </div>
                    </div>
                    {(errors?.title && touched?.title) ? <span className='require-text'>{errors?.title}</span> : null}
                    <div class="form-with-lable mt-2">
                        <label class="mt-1 form_label">Share Text</label>
                        <div class="input-box">
                            <textarea type='text' placeholder='Enter Share Text' rows={14} name='share_txt' onBlur={handleBlur} onChange={handleChange} value={values?.share_txt} /><br />                        
                        </div>
                    </div>
                    {(errors?.share_txt && touched?.share_txt) ? <span className='require-text'>{errors?.share_txt}</span> : null}
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Status"
                        className='float-end mt-2'
                        checked={values?.status}
                        onChange={(e) => setFieldValue('status', !values?.status)}
                    />
                    <div className='text-center mt-4'>
                        <button type='submit' className='theme-btn px-4'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Addform