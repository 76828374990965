
import axios from 'axios';

const getApi = async (url, header = false) => {
    try {
        const options = header
            ? {
                  headers: { 'x-access-token': localStorage.getItem(process.env.REACT_APP_SECRET_KEY) },
              }
            : {};
        
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}${url}`, options);

        return {
            status: response.status,
            data: response.data.data,
            dataCount: response.data.dataCount,
            totalPage: response.data.totalPage,
            currentPage: response.data.currentPage,
            message: response.data.message,
        };
    } catch (error) {
        const { response } = error;
        if (response && response.status === 401) {
            window.location.href = "/";
            localStorage.removeItem(process.env.REACT_APP_SECRET_KEY);
        } else {
            return {
                status: response?.status,
                message: response?.data?.message,
            };
        }
    }
};

const postApi = async (url, bodyParser, header = false, enctype= false) => {
    try {   
        const options = (header && enctype) ? {
            headers: { 
                'x-access-token': localStorage.getItem(process.env.REACT_APP_SECRET_KEY),
                'content-type': 'multipart/form-data'
            }} : header ? {
                headers: { 'x-access-token': localStorage.getItem(process.env.REACT_APP_SECRET_KEY) },
            } : enctype ? {
                headers: {'content-type': 'multipart/form-data'},
            } : {};
        
        const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}${url}`, bodyParser, options);

        return {
            status: response?.status,
            data: response?.data?.data,
            message: response?.data?.message,
        };
    } catch (error) {
        const { response } = error;
        if (response && response.status === 401) {
            window.location.href = "/";
            localStorage.removeItem(process.env.REACT_APP_SECRET_KEY);
        } else {
            return {
                status: response?.status,
                message: response?.data?.message,
            };
        }
    }
}

export { getApi, postApi };