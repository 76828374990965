import React, { useEffect } from "react";

import { useDispatch } from 'react-redux';
import { loginUser } from '../Helpers/Redux/StateSlice/user';

import SideBar from "./sidebar";
import NavBar from "./navbar";
import { getApi } from "../Helpers/Axios";

const Index = ({ children }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    handleUserVerify();
    handleAdminInfo();
  }, []);

  let handleUserVerify = () => {
    let token = localStorage.getItem(process.env.REACT_APP_SECRET_KEY);
    if (token === undefined || token === "" || token === null) {
      window.location.href = "/";
    }
  };

  let handleAdminInfo = async () => {
    const response = await getApi('get-admin', true);
    dispatch(loginUser(response?.data));
  }

  return (
    <>
      <NavBar />
      <SideBar />
      <div className="container_wrapper">
        {children}
      </div>
    </>
  );
};

export default Index;
