
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import {Table} from 'react-bootstrap';

// SVGs
import editSvg from "../../assets/assets_svg/white_edit.svg";

// Helpers
import { getApi, postApi } from "../../Helpers/Axios";

const TermsConditions = () => {

    const [termsConditions, setTermsConditionsData] = useState(null);    
    const [editTermsConditions, setEditTermsConditionsData] = useState(false);    
    
    useEffect(() => {
        fetchTermsConditions();
    }, [editTermsConditions])

    const fetchTermsConditions = async () => {
        let url = `terms-and-conditions?edit_id=${1}`
        await getApi(url, true).then((response) => {
            if(response?.status === 200) {
                setTermsConditionsData(response?.data?.terms_condition);
            }
        })
    }

    const handleAddTermsConditions = async () => {
        let url = `add-terms-and-conditions?edit_id=${1}`;
        let values = {"terms_condition": termsConditions}
            await postApi(url, values, true)
                .then((response) => {
                    if(response?.status === 200) {
                        setEditTermsConditionsData(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
    }

    let handleEditTermsConditions = async () => {
        setEditTermsConditionsData(true);
    }

    return(
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 breadcrumbs"><span><Link to={'/dashboard'}>Dashboard</Link> /</span> Terms & Conditions</p>
                <div className="d-flex justify-content-between align-items-center">
                {editTermsConditions ? (
                    <>
                        <button className="theme-icon-btn float-end px-3 me-3" onClick={() => setEditTermsConditionsData(false)}>Cancel</button>
                        <button className="theme-icon-btn float-end px-3" onClick={handleAddTermsConditions}>Submit</button>
                    </>
                ) : (
                    <button className="theme-icon-btn float-end px-3" onClick={handleEditTermsConditions}><img src={editSvg} alt="Edit" />Edit</button>
                )}
                </div>
            </div>

            <div className="terms-conditions-textarea mt-3">
                {editTermsConditions ? 
                    <textarea placeholder="Terms And Conditions" onChange={(e) => setTermsConditionsData(e.target.value)} value={termsConditions}></textarea> : 
                    <textarea placeholder="Terms And Conditions" readOnly value={termsConditions}></textarea>}
            </div>
        </>
    )
}

export default TermsConditions;