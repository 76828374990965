
// SVGs
import { useEffect, useState } from "react";
import UsersSvg from "../../assets/assets_svg/users.svg";
import WinnerSvg from "../../assets/assets_svg/winner_white.svg";
import { getApi } from "../../Helpers/Axios";

const Dashboard = () => {   

   const [registerUser, setRegisterUser] = useState(0);
   const [winnerUser, setWinnerUser] = useState(0);

   useEffect(() => {
      fetchUsers();
  }, [])

  const fetchUsers = async () => {
      let url = `get-dashboard`
      await getApi(url, true).then(async (response) => {
         if(response.status === 200) {
            setRegisterUser(response.data.totalUsersCount)
            setWinnerUser(response.data.winnerUsersCount)
         }
      })
  }

   return (
      <>
         <h1 className="dash-title">Dashboard</h1>
         <div className="dashboard-boxs row">
            <div className="col-md-3">
               <div className="dash-box">
                  <div className="dash-box-icons">
                     <img src={UsersSvg} alt="user svg" />
                  </div>
                  <div className="dash-box-info">
                     <p className="box-count">{registerUser}</p>
                     <p className="box-title">User Registrations</p>
                  </div>
               </div>
            </div>

            <div className="col-md-3">
               <div className="dash-box">
                  <div className="dash-box-icons">
                     <img src={WinnerSvg} alt="user svg" />
                  </div>
                  <div className="dash-box-info">
                     <p className="box-count">{winnerUser}</p>
                     <p className="box-title">Winners User</p>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Dashboard;