  import React, { useEffect, useState } from "react";

  import { useLocation, useNavigate } from 'react-router-dom';
  import { Modal } from 'react-bootstrap';
  import { useFormik } from "formik";
  import * as Yup from "yup";

  // SVGs
  import userCircleSvg from "../assets/assets_svg/usercircle.svg";
  import userSvg from "../assets/assets_svg/user.svg";
  import logoutSvg from "../assets/assets_svg/logout.svg";
  import passwordSvg from "../assets/assets_svg/password.svg";
  import eyeSvg from "../assets/assets_svg/eye.svg";
  import eyeHideSvg from "../assets/assets_svg/eyehide.svg";
  import { useSelector } from "react-redux";
  import { postApi } from "../Helpers/Axios";

  const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const admin = useSelector((state) => state?.auth?.user);
    const pathname =  location?.pathname?.split('/')[1];
    const [userMenu, setUserMenu] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const [currPassType, setCurrPassType] = useState(false);
    const [newPassType, setNewPassType] = useState(false);
    const [confPassType, setConfPassType] = useState(false);

    const handleClick = (e) => {
      const classList = e.target.classList;

      // If you want to convert the classList to an array, you can do this:
      const classesArray = Array.from(classList);

      if(!classesArray.includes('theme-icon-btn') && !classesArray.includes('user-menu-icon')) {
        setUserMenu(false)
      }
      
    };

    useEffect(() => {  
      // Attach the click event listener to the entire document
      document.addEventListener('click', handleClick);
  
      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);

    const handleLogout = () => {
      localStorage.removeItem(process.env.REACT_APP_SECRET_KEY);
      navigate("/");
    }

    const handleUserMenu = () => {
      setUserMenu(!userMenu)
    }
    
    const handlePasswordChanges = () => {
      setModalShow(true);
      setUserMenu(false);
    }

    const [initialValues, setInitialValueData] = useState ({
      curr_password: null,
      new_password: null,
      conf_password: null,
    });

    const validationSchema = Yup.object({
      curr_password: Yup.string()
        .required("Current password is required."),
      new_password: Yup.string()
        .required("New password is required."),
      conf_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], "Confirm password does not match.")
        .required("Confirm password is required.")
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm, setFieldError  } = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: async (values) => {
        values.status = values.status ? 1 : 0;
        let url = 'change-password';
        await postApi(url, values, true)
          .then((response) => {
            if(response.status === 200) {
              handleClose()
            } else if(response.status === 422) {
              setFieldError('curr_password', response.message)
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    const handleClose = () => {
      const initValue = {
        curr_password: null,
        new_password: null,
        conf_password: null,
      };

      // Resetting formik's state
      resetForm({ values: initValue });

      setCurrPassType(false);
      setNewPassType(false);
      setConfPassType(false);
      
      setModalShow(false);
    }

    return (
      <>
        <div className="navbar">
          <p className="nav-title">{pathname?.replaceAll("-", " ")}</p>
          <div>
            <div className="theme-icon-btn float-end rounded-pill position-relative" onClick={() => handleUserMenu()}>
              <img src={userCircleSvg} alt="" srcset="" className="user-menu-icon" />
            </div>
            <ul className={`user-menu ${!userMenu && 'd-none'}`}>
              <li className="user-menu-item rounded-top-2">
                <p>
                  <img src={userSvg} alt="speedometer icon"/>
                  <span>{admin?.email}</span>
                </p>
              </li>
              <li className="user-menu-item" onClick={() => handlePasswordChanges()}>
                <p>
                  <img src={passwordSvg} alt="speedometer icon"/>
                  <span>Change password</span>
                </p>
              </li>
              <li className="user-menu-item rounded-bottom-2" onClick={() => handleLogout()}>
                <p>
                  <img src={logoutSvg} alt="speedometer icon"/>
                  <span>Logout</span>
                </p>
              </li>
            </ul>
          </div>
        </div>

        <Modal show={modalShow} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>
            <div className="theme-mobel-header">
              <p className="header-title">Change Password</p>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="theme-mobel-body">
                <div class="form-with-lable mb-3">
                  <div class="input-box">
                    <input type={currPassType ? 'text' : 'password'} name="curr_password" onChange={handleChange} onBlur={handleBlur} placeholder="Enter current password" value={values.curr_password} />
                    <span className="input-box-icon" onClick={() => setCurrPassType(!currPassType)}>
                      <img src={currPassType ? eyeSvg : eyeHideSvg} alt="" srcset="" />
                    </span>
                  </div>
                  {(errors?.curr_password && touched?.curr_password) ? <span className='require-text'>{errors?.curr_password}</span> : null}
                </div>
                <div class="form-with-lable mb-3">
                  <div class="input-box">
                    <input type={newPassType ? 'text' : 'password'} name="new_password" onChange={handleChange} onBlur={handleBlur} placeholder="Enter new password" value={values.new_password} />
                    <span className="input-box-icon" onClick={() => setNewPassType(!newPassType)}>
                      <img src={newPassType ? eyeSvg : eyeHideSvg} alt="" srcset="" />
                    </span>
                  </div>
                  {(errors?.new_password && touched?.new_password) ? <span className='require-text'>{errors?.new_password}</span> : null}
                </div>
                <div class="form-with-lable mb-3">
                  <div class="input-box">
                    <input type={confPassType ? 'text' : 'password'} name="conf_password" onChange={handleChange} onBlur={handleBlur} placeholder="Enter confirm password" value={values.conf_password} />
                    <span className="input-box-icon" onClick={() => setConfPassType(!confPassType)}>
                      <img src={confPassType ? eyeSvg : eyeHideSvg} alt="" srcset="" />
                    </span>
                  </div>
                  {(errors?.conf_password && touched?.conf_password) ? <span className='require-text'>{errors?.conf_password}</span> : null}
                </div>
              </div>
              <div className="theme-mobel-footer">
                <button type="button" className="theme-btn" onClick={handleClose}>Close</button>
                <button type="submit" className="theme-btn" >Submit</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  export default Navbar;
